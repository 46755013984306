<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <!-- Brand logo-->
    <b-link class="brand-logo">
      <h2 class="brand-text text-primary ml-1">
        <b-img fluid :src="logoImg" alt="Logo da Agenda"></b-img>
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-2">Oops...</h2>
        <h2>Essa página não foi encontrada</h2>
        <p class="mb-2">ERROR-404</p>
        <p class="mb-1">Clique no botão abaixo para voltar a página inicial</p>
        <b-button variant="primary" class="mb-1 btn-sm-block" :to="{ path: '/' }"> Tela Inicial </b-button>

        <b-img v-bind="imgerror" fluid :src="imgUrl" alt="ERROR-404 Imagem" />
      </div>
    </div>
  </div>
</template>

<script>
  import { BLink, BImg, BButton, BCard } from 'bootstrap-vue'
  import { getHomeRouteForLoggedInUser } from '@/auth/utils'

  export default {
    components: {
      BLink,
      BImg,
      BButton,
      BCard,
    },
    data() {
      return {
        downImg: require('@/assets/images/pages/errorNew.png'),
        logoImg: require('@/assets/images/pages/logoAgenda.png'),
        imgerror: {
          center: true,
          width: 400,
          height: 300,
        },
      }
    },
    computed: {
      imgUrl() {
        this.downImg = require('@/assets/images/pages/errorNew.png')
        return this.downImg
      },
      logoAgenda() {
        this.logoImg = require('@/assets/images/pages/logoAgenda.png')
        return this.logoImg
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-misc.scss';
</style>
