import { render, staticRenderFns } from "./nao-encontrado.vue?vue&type=template&id=5c0224c9"
import script from "./nao-encontrado.vue?vue&type=script&lang=js"
export * from "./nao-encontrado.vue?vue&type=script&lang=js"
import style0 from "./nao-encontrado.vue?vue&type=style&index=0&id=5c0224c9&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports